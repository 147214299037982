import React, { useEffect, useState } from "react";
import "./App.scss";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    //USE BEM CONVENTIONS FOR CLASS-NAMES
    <div className="app">
      <Routes />
      <Toaster
        toastOptions={{
          duration: 2500,
          position:"top-right",
          success: {
            style: {
              border: "2px solid lightgreen",
              color:'white',
              background:"linear-gradient(#00486c, #00a0de)",
              letterSpacing: 0.5,
              fontWeight:"bold"
            },
          },
          error: {
            style: {
              border: "2px solid red",
              color:'white',
              background:"linear-gradient(#00486c, #00a0de)",
              letterSpacing: 0.5,
              fontWeight:"bold"
            },
          },
        }}
      />
    </div>
  );
}

export default App;

